//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-568:_2552,_9620,_528,_572,_9884,_6858,_9348,_3276;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-568')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-568', "_2552,_9620,_528,_572,_9884,_6858,_9348,_3276");
        }
      }catch (ex) {
        console.error(ex);
      }